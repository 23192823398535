import React, {useState} from 'react';
import AccountCircleSharpIcon from '@mui/icons-material/AccountCircleSharp';
import AddSharpIcon from '@mui/icons-material/AddSharp';
import HomeSharpIcon from '@mui/icons-material/HomeSharp';
import {IconButton, Typography, Box, Drawer, ListItemButton} from '@mui/material';
import {List, ListItem, ListItemText, useTheme} from '@mui/material';
import {useNavigate} from 'react-router-dom';
import HelpOutline from '@mui/icons-material/HelpOutline';
import PasswordIcon from '@mui/icons-material/Password';
import WorkspacesIcon from '@mui/icons-material/Workspaces';
import useAuthStore from '../../store/authStore';
import useLeaderStore from '../../store/leaderStore';
import SelectInput from './SelectInput';
import {getKeyByValue} from '../../utils';
import {CONSTANTS} from '../../constants';
import useAdminStore from '../../store/adminStore';
import {addUserRoleApi, deleteUser} from '../../api';
import LogoutIcon from '@mui/icons-material/Logout';
import EditIcon from '@mui/icons-material/Edit';
import Person from '@mui/icons-material/Person';
import SettingsIcon from '@mui/icons-material/Settings';
import theme from '../../styles/theme';
import { DeleteOutline } from '@mui/icons-material';
import Modal from './Modal';
import useTranslations from '../../hooks/useTranslation';

const IconButtonContainer = ({icon, label, onClick}) => { 
  const theme = useTheme();
  const iconColor = theme.palette.icon.primary;
  return (
    <IconButton
      disableRipple
      disableFocusRipple
      disableTouchRipple
      onClick={onClick}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        color: iconColor
      }}
    >
      {icon}
      <Typography sx={{fontSize: '1rem'}}>{label}</Typography>
    </IconButton>
  );
};

const BottomBar = ({buttonInputs}) => {
  const theme = useTheme();
  const textColor = theme.palette.text.secondary;
  const iconColor = theme.palette.icon.primary;
  const {translate} = useTranslations();
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const {organizationSelected} = useAdminStore(state => state);
  const {user, logoutUser, loginNewUser} = useAuthStore(state => state);
  const {clearLeaderData, group} = useLeaderStore(state => state);
  const {clearAdminData} = useAdminStore(state => state);
  const [modalOpen, setModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const user_role = user?.user_role;
  const toggleDrawer = newOpen => () => {
    setOpen(newOpen);
  };
  const [selectedRole, setSelectedRole] = useState(user?.user_role);
  const rolesArray = user?.user_permissions ? user?.user_permissions?.split(',') : [];
  const userRole = rolesArray?.map(role => {
    const roles = getKeyByValue(CONSTANTS.USER_ROLES, role);
    return {
      label: roles,
      value: roles
    };
  });
  const currentYear = new Date().getFullYear();
  const logoutUserHandler = () => {
    logoutUser();
    clearLeaderData();
    clearAdminData();
  };

  const handleRoleChange = async newRole => {
    setSelectedRole(newRole);
    try {
      const roleData = {
        permission: newRole,
        is_role_change: true,
        orgId: organizationSelected
      };
      const res = await addUserRoleApi(roleData);
      if (res?.data?.status === 200) {
        loginNewUser({...res?.data?.data, user_role: newRole});
      }
    } catch (error) {}
  };

  const handleDeleteUser = async() => {
    try {
      setLoading(true);
      const res = await deleteUser(user?.id);
      if (res?.data?.status === 200) {
        setModalOpen(false);
        logoutUserHandler();
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  }
  const DrawerList = (
    <Box sx={{width: 250, overflow: 'hidden'}} role="presentation">
      <List
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          height: '100dvh'
        }}
      >
        <div>
          {userRole?.length > 1 && (
            <>
              <Typography color={textColor} pl={2} fontWeight={'bold'}>
                {translate("Currently Logged In: ")}{translate(selectedRole || user_role)}
              </Typography>
            </>
          )}
          {userRole?.length > 1 && (
            <Box sx={{marginX: '20px'}}>
              <SelectInput value={selectedRole} setValue={handleRoleChange} optionArray={userRole} />
            </Box>
          )}
          {(user_role === 'LEADER' || user_role === 'DISCIPLE') && (
            <>
              <ListItem disablePadding>
                <ListItemButton
                  sx={{
                    borderBottom: '1px solid #ffffff',
                    backgroundColor: 'rgba(0, 0, 0, 0.5)',
                    borderRadius: theme.shape.borderRadius,
                    '&:hover': {
                      backgroundColor: 'rgba(0, 0, 0, 0.7)'
                    },
                    fontSize: '1.2rem'
                  }}
                  onClick={() => navigate('/journey?isEdit=true')}
                >
                  <WorkspacesIcon style={{color: {iconColor}, marginRight: '10px'}} />
                  <ListItemText primary={translate("Journey")} />
                </ListItemButton>
              </ListItem>
            </>
          )}
          {(user_role === 'DISCIPLE' || user_role === 'ADMIN' || user_role === 'LEADER') && (
            <>
              <ListItem disablePadding>
                <ListItemButton
                  sx={{
                    borderBottom: '1px solid #ffffff',
                    backgroundColor: 'rgba(0, 0, 0, 0.5)',
                    borderRadius: theme.shape.borderRadius,
                    '&:hover': {
                      backgroundColor: 'rgba(0, 0, 0, 0.7)'
                    },
                    fontSize: '1.2rem'
                  }}
                  onClick={() => navigate('/edit-profile')}
                >
                  <Person style={{color: {iconColor}, marginRight: '10px'}} />
                  <ListItemText primary={translate("Profile")} />
                </ListItemButton>
              </ListItem>
              <ListItem disablePadding>
                <ListItemButton
                  sx={{
                    borderBottom: '1px solid #ffffff',
                    backgroundColor: 'rgba(0, 0, 0, 0.5)',
                    borderRadius: theme.shape.borderRadius,
                    '&:hover': {
                      backgroundColor: 'rgba(0, 0, 0, 0.7)'
                    },
                    fontSize: '1.2rem'
                  }}
                  onClick={() => navigate('/contact-us')}
                >
                  <HelpOutline style={{color: {iconColor}, marginRight: '10px'}} />
                  <ListItemText primary={translate("Help")} />
                </ListItemButton>
              </ListItem>

              <ListItem disablePadding>
                <ListItemButton
                  sx={{
                    borderBottom: '1px solid #ffffff',
                    backgroundColor: 'rgba(0, 0, 0, 0.5)',
                    borderRadius: theme.shape.borderRadius,
                    '&:hover': {
                      backgroundColor: 'rgba(0, 0, 0, 0.7)'
                    },
                    fontSize: '1.2rem'
                  }}
                  onClick={() => navigate('/change-password')}
                >
                  <PasswordIcon style={{color: {iconColor}, marginRight: '10px'}} />
                  <ListItemText primary={translate("Change Password")} />
                </ListItemButton>
              </ListItem>
              {/* TODO: uncomment this code for delete user */}
              <ListItem disablePadding>
                <ListItemButton
                  sx={{
                    borderBottom: '1px solid #ffffff',
                    backgroundColor: 'rgba(0, 0, 0, 0.5)',
                    borderRadius: theme.shape.borderRadius,
                    '&:hover': {
                      backgroundColor: 'rgba(0, 0, 0, 0.7)'
                    },
                    fontSize: '1.2rem'
                  }}
                  onClick={() => setModalOpen(true)}
                >
                  <DeleteOutline style={{color: {iconColor}, marginRight: '10px'}} />
                  <ListItemText primary={translate("Delete My Account")} />
                </ListItemButton>
              </ListItem>
            </>
          )}
          <ListItem disablePadding>
            <ListItemButton
              sx={{
                borderBottom: `1px solid ${textColor}`,
                backgroundColor: 'rgba(0, 0, 0, 0.5)',
                borderRadius: theme.shape.borderRadius,
                '&:hover': {
                  backgroundColor: 'rgba(0, 0, 0, 0.7)'
                },
                fontSize: '1.2rem'
              }}
              onClick={() => logoutUserHandler()}
            >
              <LogoutIcon style={{color: {iconColor}, marginRight: '10px'}} />
              <ListItemText primary={translate("Log Out")} />
            </ListItemButton>
          </ListItem>
        </div>
        <Box mb={2}>
          <Typography textAlign="center" mb={2} color={textColor}>
            {translate("© {currentYear} SD: All right reserved.").replace("{currentYear}", currentYear )}
          </Typography>
          <ListItem disablePadding>
            <img width={250} src="/trademark.svg" alt="SG Logo" />
          </ListItem>
          <Typography mt={2} textAlign="center">
            {translate("Developed By:")}{' '}
            <a target="_blank" className="hyper_link" href={CONSTANTS.D2I_SITE.URL}>
              D2i Technology
            </a>
          </Typography>
        </Box>
      </List>
    </Box>
  );

  return (
    <>
      <Box display="flex" height={70} position="sticky" bottom={0} left={0} right={0} alignItems="center" justifyContent="space-around" sx={{backgroundColor: 'black', cursor: 'pointer', marginTop: 'auto'}}>
        <IconButtonContainer onClick={buttonInputs[0].onClick} icon={<HomeSharpIcon sx={{fontSize: '1.8rem'}} />} label={buttonInputs[0].text} />
        {buttonInputs[1]?.isShow && <IconButtonContainer onClick={buttonInputs[1].onClick} icon={<AddSharpIcon sx={{fontSize: '1.8rem'}} />} label={buttonInputs[1].text} />}
        {buttonInputs[2]?.isShow && <IconButtonContainer onClick={buttonInputs[2].onClick} icon={!group?.reading_added ? <AddSharpIcon sx={{fontSize: '1.8rem'}} /> : <EditIcon sx={{fontSize: '1.8rem', color: 'white'}} />} label={buttonInputs[2].text} />}
        <IconButtonContainer onClick={() => setOpen(true)} icon={<SettingsIcon sx={{fontSize: '1.8rem'}} />} label={buttonInputs[buttonInputs.length - 1].text} />
      </Box>
      <Drawer open={open} onClose={toggleDrawer(false)} anchor={'right'}>
        {DrawerList}
      </Drawer>
      <Modal
          open={modalOpen}
          setOpen={setModalOpen}
          title={translate("Delete Account")}
          titleStyles={{color: textColor,fontWeight:'bold'}}
          buttons={[
            {name: translate("Close"), onClick: () => setModalOpen(false)},
            {name: translate("Delete"), onClick: () => handleDeleteUser(), loading: loading}
          ]}
        >
          <Typography sx={{color: textColor}}>{translate("Are you sure you want to delete your account? All associated data will be removed from the application soon, and you will be logged out immediately.")}</Typography>
        </Modal>
    </>
  );
};

export default BottomBar;
