import React, {useState} from 'react';
import {Box, Button, IconButton, List, ListItemButton, ListItemText, Typography,Menu, MenuItem, useTheme} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import {deleteUser, updateGroupApi} from '../../../api';
import Modal from '../../../components/common/Modal';
import Loader from '../../../components/common/Loader';
import useTranslations from '../../../hooks/useTranslation';

const ItemList = ({items, handleClick, selectedItem, height, title, deleteIcon = false, reloadData, loader, marginBottom, moreVert = false}) => {
  const {translate} = useTranslations();
  const theme = useTheme();
  const modalBgColor = theme.palette.modal.bgcolor;
  const modalTextColor = theme.palette.modal.textcolor;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalDeleteOpen, setIsModalDeleteOpen] = useState(false);
  const [isModalArchieveOpen, setIsModalArchieveOpen] = useState(false);
  const [toDelete, setToDelete] = useState(null);
  const [user, setUser] = useState(selectedItem);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedItemId, setSelectedItemId] = useState(null);

  const handleOpenModal = item => {
    setToDelete(item);
    setIsModalOpen(true);
  };

  const handleMenuOpen = (e, itemId) => {
    setAnchorEl(e.currentTarget);
    setSelectedItemId(itemId);
    e.stopPropagation();
  };

  const handleMenuClose = (e) => {
    if(e){
      e.stopPropagation();
    }
    setAnchorEl(null);
    setSelectedItemId(null);
    setIsModalArchieveOpen(false);
    setIsModalDeleteOpen(false);
  };
 
  const handleCloseModal = () => {
    setToDelete(null);
    setIsModalOpen(false);
  };

  const handleGroupStatusUpdate = async (groupId, status) => { 
    try {
        let payload;
        // Determine the payload based on the status
        switch (status) {
        case 'ARCHIEVED':
          payload = { isMarkGroupAsArchived: true };
          break;
        case 'DELETED':
          payload = { isMarkGroupAsDeleted: true };
          break;
        default:
          return;
      }
        const res = await updateGroupApi(groupId, payload);
        if (res.data.status === 200) {
          handleMenuClose();
            if (reloadData) {
                reloadData();
            }
        }
    } catch (error) {
      } finally {
        handleMenuClose();  
      }
};
const handleArchiveModal = (e) => {
  setIsModalArchieveOpen(true);
  e.stopPropagation();
};
const handleDeleteModal = (e) => {
  setIsModalDeleteOpen(true);
  e.stopPropagation();
};

  // Handler for Archiving the group
  const handleArchiveGroup = (e,itemId) => {
    handleGroupStatusUpdate(itemId, 'ARCHIEVED');
    e.stopPropagation();
  };

  // Handler for Deleting the group (opens confirmation modal)
  const handleDeleteGroup = (e,itemId) => {
    handleGroupStatusUpdate(itemId, 'DELETED');
    e.stopPropagation();
  };

  const handleConfirmDelete = () => {
    if (toDelete) {
      handleDelete(toDelete);
      handleCloseModal();
    }
  };

  const handleDelete = async id => {
    try {
      const res = await deleteUser(id);
      if (res?.data?.status === 200) {
        if (reloadData) {
          reloadData();
        }
      }
    } catch (error) {}
  };
  return (
    <Box width="100%" height={height} overflow="auto" bgcolor="#FFF" borderRadius="12px" marginBottom={marginBottom}>
      {loader ? (
        <Loader color="#9e0001" />
      ) : (
        <>
          {title && (
            <Typography variant="h6" gutterBottom color="#d32f2f" fontWeight="bold" textAlign="left" pl={3} pt={3}>
              {title}
            </Typography>
          )}
          <List>
            {items &&
              items.length !== 0 &&
              items.map((item, index) => (
                <ListItemButton
                  key={index}
                  selected={selectedItem === item}
                  onClick={() => handleClick(item)}
                  sx={{
                    paddingLeft: '24px',
                    '&.Mui-selected': {
                      color: '#d32f2f'
                    }
                  }}
                >
                  <ListItemText
                    primary={item?.name ? item?.name : item?.first_name + item?.last_name}
                    primaryTypographyProps={{
                      sx: {
                        color: '#000'
                      }
                    }}
                  />
                   {moreVert && (
                    <>
                      <IconButton style={{ color: '#d32f2f' }} onClick={(e) => handleMenuOpen(e, item.user_id || item.id)}>
                        <MoreVertIcon />
                      </IconButton>
                      <Menu
                        anchorEl={anchorEl}
                        open={Boolean(anchorEl) && selectedItemId === (item.user_id || item.id)}
                        onClose={handleMenuClose}
                        sx={{
                          '& .MuiPaper-root': {
                            backgroundColor: 'white',
                            color: 'black',
                          },
                        }}
                      >
                        <MenuItem style={{backgroundColor: 'white', color: 'black'}} onClick={(e) => handleArchiveModal(e)}>
                          {translate('ARCHIEVE GROUP')}
                        </MenuItem>
                        <MenuItem style={{backgroundColor: 'white', color: 'black'}} onClick={(e) => handleDeleteModal(e)}>
                          {translate('DELETE GROUP')}
                        </MenuItem>
                      </Menu>
                    </>
                  )}
                  {deleteIcon === 'true' && (
                    <IconButton>
                      <DeleteIcon style={{color: '#d32f2f'}} onClick={() => handleOpenModal(item.user_id || item.id)} />
                    </IconButton>
                  )}
                </ListItemButton>
              ))}
          </List>

          <Modal backgroundColor={modalBgColor} color={modalTextColor} open={isModalOpen} onClose={handleCloseModal} title={translate("Confirm Delete")}>
            <Box>
              <Typography variant="body1" mb={4}>
                {translate("Are you sure you want to delete ")}{user?.name}?
              </Typography>
              <Box display="flex" justifyContent="flex-end">
                <Button variant="contained" color="error" onClick={handleCloseModal} sx={{mr: 1}}>
                  {translate("Cancel")}
                </Button>
                <Button variant="contained" color="secondary" onClick={handleConfirmDelete}>
                  {translate("Delete")}
                </Button>
              </Box>
            </Box>
          </Modal>
          {/* For Archieve Group */}
          <Modal backgroundColor={modalBgColor} color={modalTextColor} open={isModalArchieveOpen} onClose={handleMenuClose} title={translate('Confirm Archieve the Group')}>
            <Box>
              <Typography variant="body1" mb={4} >
                {translate('Are you sure you want to archieve the group?')}
              </Typography>
              <Box display="flex" justifyContent="flex-end">
                <Button variant="contained" color="secondary" onClick={handleMenuClose} sx={{mr: 1}}>
                  {translate('Cancel')}
                </Button>
                <Button variant="contained" color="secondary" onClick={(e) => handleArchiveGroup(e,selectedItemId)}>
                  {translate('Archieve')}
                </Button>
              </Box>
            </Box>
          </Modal>
          {/* For Delete Group */}
          <Modal backgroundColor={modalBgColor} color={modalTextColor} open={isModalDeleteOpen} onClose={handleMenuClose} title={translate('Confirm Delete the Group')}>
            <Box>
              <Typography variant="body1" mb={4} >
                {translate('Are you sure you want to delete the group?')}
              </Typography>
              <Box display="flex" justifyContent="flex-end">
                <Button variant="contained" color="secondary" onClick={handleMenuClose} sx={{mr: 1}}>
                  {translate('Cancel')}
                </Button>
                <Button variant="contained" color="secondary" onClick={(e) => handleDeleteGroup(e,selectedItemId)}>
                  {translate('Delete')}
                </Button>
              </Box>
            </Box>
          </Modal>
        </>
      )}
    </Box>
  );
};

export default ItemList;
