import React from 'react';
import {Dialog, DialogTitle, DialogContent, DialogActions, useTheme} from '@mui/material';
import CustomButton from './Button';

const Modal = ({children, open, setOpen, title, buttons, backgroundColor, color = 'white', height, titleStyles}) => {
  const theme = useTheme();
  const modalbgColor = theme.palette.modal.bgcolor;
  const modalTextColor = theme.palette.modal.textcolor;
  return (
    <Dialog
      open={open}
      onClose={setOpen}
      PaperProps={{
        sx: {
          backgroundColor: modalbgColor,
          color: modalTextColor,
          padding: 1,
          overflow: 'hidden',
          width: '100vw',
          ...(height && {height: height})
        }
      }}
    >
      <DialogTitle sx={{backgroundColor: modalbgColor, color: modalTextColor, padding: '10px', ...titleStyles }}>{title}</DialogTitle>

      <DialogContent sx={{backgroundColor: modalbgColor, color: modalTextColor, padding: '10px'}}>{children}</DialogContent>

      <DialogActions>
        {buttons?.map((button, index) => {
          return <CustomButton type="outlined" key={index} onClick={button.onClick} text={button?.name} loading={button.loading} disabled={button.disabled} />;
        })}
      </DialogActions>
    </Dialog>
  );
};

export default Modal;
